import { GetStaticProps, InferGetStaticPropsType, NextPage } from "next";
import { useTranslation } from "next-i18next";
import CrowlynHead from "@components/crowlyn-head/crowlyn-head";
import { Footer } from "@components/sections/footer/footer";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { i18translateType } from "../@types/types-body";
import dynamic from "next/dynamic";

type FourOFourPageProps = InferGetStaticPropsType<typeof getStaticProps>;

const NavMenuWithoutBanner = dynamic(
  () => import("@components/sections/header/menu/nav-menu-without-banner"),
  {
    suspense: true,
  }
);
const FourZeroFourSection = dynamic(
  () => import("@components/sections/error-section/four-zero-four-section"),
  {
    suspense: true,
  }
);

const FourOFourPage: NextPage<FourOFourPageProps> = (server_side_props: any) => {
  const namespace = "errors";
  const router = useRouter();
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <>
      <CrowlynHead
        description={t("404.seo_description")}
        title={t("404.seo_title")}
        keywords={"404.seo_keywords"}
        lang={server_side_props.locale ?? router.locale}
        path={router.pathname}
        englishPath={"/404"}
        germanPath={"/404"}
        image={"/images/banners/company-banner.webp"}
      />
      <NavMenuWithoutBanner />
      <FourZeroFourSection namespace={namespace} />
      <Footer />
    </>
  );
};
export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(
          context.locale as string,
        ["common", "errors"],
        null
      )),
    },
  };
};
export default FourOFourPage;
